import moment from 'moment';

jQuery( document ).ready(function($) {
    // Tour
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/tyler%20Shaw/events?app_id=45PRESS_tyler_shaw',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event" data-bit-id="' + event.id + '">';
                    html += '<div class="event-info">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
                    html += '<div class="event-venue-location">';
                    html += '<div class="event-venue">' + event.venue.name + '</div>';
                    html += '<div class="event-location">' + event_location + '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="event-links">';
                    html += '<a href="' + event.url + '" target="_blank" class="btn btn-tertiary">Tickets</a>';
                    html += '<a href="' + event.url + '" target="_blank" class="btn btn-secondary">RSVP</a>';
                    html += '</div>';
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( 'No upcoming events.' );
            }
        }
    } );
});